import { $, addFilter } from '@situation/setdesign.util';

addFilter('media-carousel/owl-settings', (settings) => ({
    ...settings,
    navText: [
        '<svg><use xlink:href="#icon-arrow-left"></use></svg>',
        '<svg><use xlink:href="#icon-arrow"></use></svg>',
    ],
}));

addFilter(
    'media-carousel/nav-arrow-top',
    (top, $slider) => Math.min(
        ...$slider
            .find('.active figure')
            .map((i, el) => $(el).outerHeight())
            .get(),
    ) / 2,
);
